import React, { useEffect, useState } from "react"
import axios from "axios"
import { API } from "../../../config/api"
import { Container, Row, Col } from "react-bootstrap"
import BlogCard from "../../cards/blog-card"
import DetailNews from "../../../pages/detail-news"

const DataNews = () => {
  const [data, setData] = useState([])
  const fetchData = async () => {
    const response = await axios
      .get(`${API.URL}/api/v1/news/view-more?size=3&page=1`)
      .then(response => {
        setData(response?.data?.data)
      })
      .catch(err => {
        console.log(err)
      })
    return response
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <section className="blog-style-one blog-page" id="blog">
      <Container>
        <Row>
          {data?.map(
            (
              { path, id, author, date, comments, title, text, link },
              index
            ) => (
              <Col
                key={`Article terbaru & promo menarik-${index}`}
                lg={4}
                md={6}
                sm={12}
              >
                <BlogCard
                  image={path}
                  title={title}
                  link={`/detail-article/${id}`}
                />
              </Col>
            )
          )}
        </Row>
      </Container>
    </section>
  )
}

export default DataNews
