import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import NavLinks from "./nav-links"
import logoImage from "../assets/images/KLABBELANJA_HORIZONTAL.png"

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false)
  const menuEl = useRef(null)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true)
    } else if (window.scrollY < 70) {
      setSticky(false)
    }
  }

  return (
    <header className="site-header header-one">
      <nav
        className={`navbar navbar-expand-lg navbar-light header-navigation stricky  ${sticky === true ? "stricky-fixed" : " "
          }`}
        style={{ flexDirection: 'row', display: 'flex' }}
      >
        <div className="container" style={{ justifyContent: 'space-between' }}>
          <div className="logo-box" style={{ paddingRight: 0 }}>
            <Link to="/" className="navbar-brand">
              <img src={logoImage} alt="Awesome alter Text" width={180} height={50} />
            </Link>
            <button
              className="menu-toggler"
              onClick={e => {
                e.preventDefault()
                menuEl.current.classList.toggle("active")
              }}
            >
              <span className="fa fa-bars"></span>
            </button>
          </div>

          <div className="main-navigation" ref={menuEl}>
            <NavLinks />
          </div>

        </div>

      </nav>
    </header>
  )
}

export default HeaderOne
