import React from "react"
import Layout from "../components/layout"
import DataNews from "../components/api/news/news"
import Footer from "../components/footer"
import Seo from "../components/seo"
import HeaderOne from "../components/header-one"
import HeaderNews from "../components/news"

const News = () => {
  return (
    <>
      <Layout pageTitle="Home | Klabbelanja">
        <Seo title="Home | Klabbelanja" />
        <HeaderOne />
        <HeaderNews />
        <DataNews />
        <Footer />
      </Layout>
    </>
  )
}

export default News
