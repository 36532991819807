import React, { Fragment, useRef } from "react"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"

const NavLinks = () => {
  const subMenuEl = useRef(null)

  return (
    <ul className="one-page-scroll-menu navigation-box" ref={subMenuEl}>
      <li>
        <ScrollLink
          activeClass="current"
          to="banner"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <Link to="/">
            Home
          </Link>
        </ScrollLink>
        {/* <Fragment>
          <SubNavToggler
            onClick={e => {
              subMenuEl.current.querySelectorAll(".sub-menu").forEach(item => {
                item.classList.remove("active")
              })

              let clickedItem = e.currentTarget.parentNode
              console.log(clickedItem)
              clickedItem.querySelector(".sub-menu").classList.toggle("active")
            }}
          />
          <ul className="sub-menu">
            <li>
              <Link to="/">Home One</Link>
            </li>
            <li>
              <Link to="/index-2">Home Two</Link>
            </li>
          </ul>
        </Fragment> */}
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="service"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Mengapa klabbelanja
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="features"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Partnership
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="faq"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          FAQ
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="blog"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <Link to="/">
            Article
          </Link>
        </ScrollLink>
      </li>
      <li>
        <div style={{ marginRight: 10, marginLeft: 20, padding: 10, backgroundColor: 'white', borderRadius: 10 }} >
          <a href="https://app.klabbelanja.com" className="header-btn">
            Menuju Aplikasi
          </a>
        </div>
      </li>
    </ul>
  )
}

export default NavLinks
